.plan-container {
    width: 700px;

    :global(.rs-modal-content) {
        padding: 0 !important;
        border-radius: 4px;

        :global(.rs-modal-body) {
            margin-top: 0;
            padding-bottom: 0;
        }
    }

    :global(.rs-modal-footer) {
        padding: 24px;
        border-top: 1px solid #EAEAEA;

        button {
            text-transform: uppercase;
            font-weight: bold;
            padding: 8px 27px;
        }

        :global(.rs-uploader) {
            :global(.rs-uploader-trigger-btn) {

                text-transform: uppercase;
                font-weight: bold;
                padding: 8px 27px;
                color: #378EF0;
                border: 1px solid #378EF0;
                background-color: #FFFFFF;
            }
        }
    }
}

.header-title {
    margin: 20px 0 0 20px;
    display: flex;
    align-items: center;
    h1 {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-right: 8px;
    }
}

.plan-content {
    padding: 24px;
}

.box-Wrapper {
    display: flex;
}

.text-Wrapper {
    margin-top: 32px;
}

.bottom-text {
    margin-top: 16px;
}
